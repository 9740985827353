#CybotCookiebotDialog {
  font-family: inherit !important;
  pointer-events: auto;
  z-index: 9999 !important;
}

#CybotCookiebotDialog .CybotCookiebotScrollContainer {
  border-bottom: none !important;
  min-height: auto !important;
}

#CybotCookiebotDialogFooter {
  padding-top: 0 !important;
}

#CybotCookiebotDialog *,
#CybotCookiebotDialogBodyUnderlay * {
  font-size: 0.75rem !important;
}

@media not all and (min-width: 1280px) {
  #CybotCookiebotDialog {
    top: calc(100% - 8px) !important;
    transform: translate(-50%, -100%) !important;
  }
}

#CybotCookiebotDialogPoweredbyCybot,
#CybotCookiebotDialogPoweredByText,
#CybotCookiebotDialogHeader,
#CybotCookiebotDialogBodyBottomWrapper,
#CybotCookiebotDialogBodyContentTitle,
#CybotCookiebotDialog .CybotCookiebotDialogBodyBottomWrapper,
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none !important;
}

#CybotCookiebotDialogNav {
  border-bottom: none !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink {
  text-transform: uppercase;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: none !important;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
}

#CybotCookiebotDialog .CybotCookiebotDialogContentWrapper {
  align-items: center !important;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border-width: 1px !important;
  padding: 0.625rem 1rem !important;
}

@media screen and (min-width: 1280px) {
  #CybotCookiebotDialog.CybotEdge .CybotCookiebotDialogBodyBottomWrapper {
    display: none !important;
  }

  #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogBodyButtons {
    max-width: none !important;
  }

  #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonsWrapper {
    flex-direction: row !important;
  }

  #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    width: auto !important;
    white-space: nowrap !important;
    margin-bottom: 0 !important;
  }

  #CybotCookiebotDialog.CybotEdge #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:first-of-type {
      margin-top: 0.5em;
  }
}
